import service from '../request'
const baseUrl = window.globalUrl.HOME_API;

// 供求资讯 - 供应信息-托管
export const getSupplyInfoOfTrusteeship= (params) => {
  return service({
    method: 'get',
    url: `${baseUrl}/land-trustship/orderGroup/page`,
    params: params
  })
}

// 供求资讯 - 供应信息-农资
export const getSupplyInfoOfMaterials= (params) => {
  return service({
    method: 'get',
    url: `${baseUrl}/trade-website/agricultural/getAgriculturalList`,
    params: params
  })
}
// 供求资讯 - 供应信息-农服
export const getSupplyInfoOfService= (params) => {
  return service({
    method: 'get',
    url: `${baseUrl}/trade-website/agricultural/getMachineryList`,
    params: params
  })
}
// 供求资讯 - 供应信息-农技
export const getSupplyInfoOfTechnology= (params) => {
  return service({
    method: 'get',
    url: `${baseUrl}/trade-website/agri/queryAgriServiceTeam`,
    params: params
  })
}

// 供求资讯 - 求购信息-托管
export const getPurchaseInfoOfTrusteeship= (params) => {
  return service({
    method: 'get',
    url: `${baseUrl}/trade-website/order/pageList`,
    params: params
  })
}

// 个人
export const getPurchaseInfoOfTrusteeshipMy= (params) => {
  return service({
    method: 'get',
    url: `${baseUrl}/land-trustship/order/page`,
    params: params
  })
}

// 农技服务-获取专家名录
export const getExpertInfo= (params) => {
  return service({
    method: 'get',
    url: `${baseUrl}/trade-website/expertInfo/page`,
    params: params
  })
}

// 农技服务-获取远程诊断
export const getZXInfo= (params) => {
  return service({
    method: 'get',
    url: `${baseUrl}/trade-website/consult/page`,
    params: params
  })
}

// 农技服务-上传咨询文件
export const uploadZXFile = (params) => {
  return service({
    method: 'post',
    url: `${baseUrl}/trade-website/consult/uploadFile`,
    data: params,
  })
}


// 农技服务-新增咨询
export const addZX = (params) => {
  return service({
    method: 'post',
    url: `${baseUrl}/trade-website/consult/add`,
    data: params,
  })
}

// 农资服务详细信息查询
export const getAgriculturalDetail= (params) => {
  return service({
    method: 'get',
    url: `${baseUrl}/trade-website/agricultural/getAgriculturalDetail`,
    params: params
  })
}

// 查询农资服务列表全部数据
export const getAgriculturalAll = (params) => {
  return service({
    method: 'get',
    url: `${baseUrl}/trade-website/agricultural/getAgriculturalAll`,
    params: params
  })
}

// 查询农机全部数据
export const getMachineryAll = (params) => {
  return service({
    method: 'get',
    url: `${baseUrl}/trade-website/agricultural/getMachineryAll`,
    params: params
  })
}

// 条件分页查询贷款列表
export const getBankList = (params) => {
  return service({
    method: 'post',
    url: `${baseUrl}/trade-website/bank/loan/list`,
    data: params
  })
}
